/* Theme color vars */
@accentColor: rgba(35, 153, 229, 1);
@accentColor80: rgba(35, 153, 229, 0.8);
@accentColor70: rgba(35, 153, 229, 0.7);
@accentColor50: rgba(35, 153, 229, 0.5);
@accentColor40: rgba(35, 153, 229, 0.4);
@accentColor30: rgba(35, 153, 229, 0.3);
@accentColor20: rgba(35, 153, 229, 0.2);
@accentColor10: rgba(35, 153, 229, 0.1);
@accentColor075: rgba(35, 153, 229, 0.075);
@accentColor5: rgba(35, 153, 229, 0.05);
@accentColor3: rgba(35, 153, 229, 0.03);
@accentColor70Solid: #65B7ED;
@accentColor10Solid: #E9F5FC;
@accentColor5Solid: #F4FAFE;
@accentHEX: #2399E5;

:root {
  // Colors
  // Primary
  --color-primary-50: #F2F7FD;
  --color-primary-75: #EBF2FB;
  --color-primary-100: #D5E4F7;
  --color-primary-200: #BDD6F3;
  --color-primary-300: #A0C6EF;
  --color-primary-400: #7EB6EB;
  --color-primary-500: #4EA3E7;
  --color-primary-600: #2399e5;
  --color-primary-700: #1F86C9;
  --color-primary-800: #1B78B4;
  --color-primary-900: #18689C;
  --color-primary-1000: #13557F;
  --color-primary-1100: #0E3C5A;
}

// Overrides
body {
  &.modern-theme {
    #formio-form-wizard {
      .formio-component-tabs {
        > .card:not(.card-vertical) {
          --tab-pill-active-bg: var(--color-primary-600);
          --tab-pill-active-text-color: white;
          --tab-pill-bg: #313945;
          --tab-pill-text-color: white;
          --tab-pill-top-left-radius: calc(@roundedCorners + 2px);
          --tab-pill-top-right-radius: calc(@roundedCorners + 2px);
          --tab-pill-bottom-left-radius: calc(@roundedCorners + 2px);
          --tab-pill-bottom-right-radius: calc(@roundedCorners + 2px);
          --tab-heading-body-gap: 10px;
  
          > .card-header .card-header-tabs {
            gap: 10px;
  
            &::before,
            &::after {
              display: none;
            }
          }
          > .card-header + .card-body.tab-pane.active {
            border-top-left-radius: var(--tab-body-border-radius);
          }
        }
      }
    }
  }
}